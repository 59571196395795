import Enum from './enum'

/**
 * インポートトラン ステータス
 */
class ImportStatus extends Enum {
    static WAITING = 1; // 処理待ち
    static WIP = 2; // 処理中
    static DONE = 3; // 処理済
    static ERROR = 8; // エラー
    static SYSTEM_ERROR = 9; // システムエラー

    static values() {
        return {
            [this.WAITING]: '処理待ち',
            [this.WIP]: '処理中',
            [this.DONE]: '処理済',
            [this.ERROR]: 'エラー',
            [this.SYSTEM_ERROR]: 'システムエラー',
        }
    }
}

export default ImportStatus;
