/**
 * インポート履歴トラン エンティティ
 */
import { isNud } from '@/utilities/typing';
import { dateYmdHi } from '@/utilities/date-format';
import ImportFormat from '@/models/enums/import-format';
import ImportStatus from '@/models/enums/import-status';
import User from '@/models/entities/user';

class ImportJob {
    import_job_id;
    user;
    import_datetime;
    import_format;
    publish_type;
    import_status;
    file_path;
    result_file_path;
    import_count;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.import_job_id = properties.import_job_id;
        this.user = new User(properties.user);
        this.import_datetime = properties.import_datetime;
        this.import_format = properties.import_format;
        this.publish_type = properties.publish_type;
        this.import_status = properties.import_status;
        this.file_path = properties.file_path;
        this.result_file_path = properties.result_file_path;
        this.import_count = properties.import_count;
    }

    get import_datetime_display() {
        return dateYmdHi(this.import_datetime);
    }

    get import_status_display() {
        return ImportStatus.get(this.import_status);
    }

    get import_format_display() {
        return ImportFormat.get(this.import_format);
    }
}

export default ImportJob;
