<template>
    <page-title
        icon="bi-upload"
        title="インポート履歴"
    >
        <button-go-create
            :to="{name: 'ImportJobAdd'}"
            text="新規インポート"
        ></button-go-create>
    </page-title>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <template v-if="import_jobs.length">
                <table-normal class="table">
                    <thead>
                        <tr class="table-dark">
                            <th class="text-center">実行日時</th>
                            <th class="text-center">実行者</th>
                            <th class="text-center">形式</th>
                            <th class="text-center">インポート件数</th>
                            <th class="text-center">ステータス</th>
                            <th class="text-center">
                                結果ファイル
                                <button-exec
                                    text=""
                                    icon="bi-arrow-clockwise"
                                    color="btn-secondary"
                                    class="ms-2"
                                    @click="refresh()"
                                ></button-exec>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="import_job in import_jobs" :key="import_job.import_job_id">
                            <td class="align-middle text-center">{{ import_job.import_datetime_display }}</td>
                            <td class="align-middle text-center">{{ import_job.user.user_name }}</td>
                            <td class="align-middle text-center">{{ import_job.import_format_display }}</td>
                            <td class="align-middle text-center">{{ import_job.import_count ? import_job.import_count : '-' }}</td>
                            <td class="align-middle text-center">{{ import_job.import_status_display }}</td>
                            <td class="align-middle text-center">
                                <template v-if="import_job.import_status === ImportStatus.DONE || import_job.import_status === ImportStatus.ERROR ">
                                    <button-exec
                                        text="ダウンロード"
                                        icon="bi-download"
                                        @click="downloadCsv(import_job)"
                                    ></button-exec>
                                </template>
                                <template v-else>
                                    {{ import_job.import_status_display }}
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table-normal>
            </template>
            <alert-no-record v-else></alert-no-record>
        </template>
    </section>
</template>

<script>
import { downloadBlob } from '@/utilities/download';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import ButtonGoCreate from '@/components/buttons/ButtonGoCreate';
import ButtonExec from '@/components/buttons/ButtonExec.vue';
import TableNormal from '@/components/tables/TableNormal.vue';
import AlertNoRecord from '@/components/notice/AlertNoRecord.vue';
import PageTitle from '@/components/PageTitle.vue';
import ImportJob from '@/models/entities/import-job';
import ImportStatus from '@/models/enums/import-status';

export default {
    name: 'ImportJobList',
    components: {
    InlineLoader,
    ButtonGoCreate,
    ButtonExec,
    TableNormal,
    AlertNoRecord,
    PageTitle,
},
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage',
    ],
    data() {
        return {
            //部分ローダー
            loading: 0,

            //Enums
            ImportStatus: ImportStatus,

            //表示データ
            import_jobs: [],
        }
    },
    mounted() {
        //製品編集権限を持たない場合
        if (this.$store.getters['auth/canEditBasic']() === false) {
            this.showErrorMessage('アクセス権限がありません');
            this.$router.push({name: 'ProductGroupList'});
        }
        this.fetchImportJobs();
    },
    methods: {
        //インポート履歴マスタ取得
        fetchImportJobs() {
            this.loading++;

            this.import_jobs.splice(0);
            this.$http.get('/import')
            .then(response => {
                for (let row of response.data) {
                    this.import_jobs.push(new ImportJob(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        //CSVダウンロード
        downloadCsv(import_job) {
            this.startScreenLoading();

            this.$http.get(`/import/${import_job.import_job_id}/csv`, {responseType: 'blob'})
            .then(response => {
                let filename = 'download.csv';
                if ('content-disposition' in response.headers) {
                    filename = response.headers['content-disposition'].replace((/attachment; filename="(.*)"/u), '$1');
                }
                downloadBlob(response.data, filename);
            })
            .finally(() => {
                this.endScreenLoading();
            });

        },
        refresh() {
            this.fetchImportJobs();
        },
    }
}
</script>

<style scoped>

</style>
